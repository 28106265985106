import React, { useState } from "react";
import { Navbar, NavbarBrand, NavbarContent, NavbarItem, Button, Dropdown, DropdownMenu, DropdownTrigger, DropdownItem, Image, Avatar, useDisclosure, Modal, Input, ModalBody, ModalContent, ModalFooter, ModalHeader } from "@nextui-org/react";
import { Link, router } from "@inertiajs/react";
import { AdjustmentsHorizontalIcon, ArrowLeftStartOnRectangleIcon, ArrowPathIcon, AtSymbolIcon, Bars4Icon, BoldIcon, BoltIcon, BuildingLibraryIcon, ChatBubbleLeftEllipsisIcon, ChatBubbleLeftRightIcon, ChevronDoubleDownIcon, ChevronDownIcon, DocumentCurrencyDollarIcon, EnvelopeIcon, FlagIcon, HomeIcon, NewspaperIcon, QuestionMarkCircleIcon, QueueListIcon, UserCircleIcon, UserGroupIcon, UsersIcon } from "@heroicons/react/24/outline";

declare global {
    interface Window {
        Chatra: any;
    }
}

interface NavbarComponentProps {
    user: {
        name: string;
        email: string;
    } | null; // Если пользователь не авторизован, это значение будет null
}

const NavbarComponent: React.FC<NavbarComponentProps> = ({ user }) => {
    const [isMenuOpen, setIsMenuOpen] = React.useState(false);
    const { isOpen, onOpen, onOpenChange } = useDisclosure();
    const [complaint, setComplaint] = useState("");
    const [email, setEmail] = useState(user ? user.email : "");

    const handleNavigation = (href: string) => {
        router.visit(href);
    };

    const handleSubmitComplaint = () => {
        alert("Жалоба отправлена!"); // Фантомная отправка жалобы
        setComplaint(""); // Очистить поле
        onOpenChange(); // Закрыть модалку без аргумента
    };

    const openChatra = () => {
        if (window.Chatra) {
            window.Chatra('openChat', true);
        } else {
            console.error('Chatra is not loaded yet.');
        }
    };

    const icons = {
        activity: <AtSymbolIcon className="size-10 text-primary" />,
        flash: <UserCircleIcon className="size-10 text-secondary" />,
        server: <EnvelopeIcon className="size-10 text-success" />,
        user: <UserGroupIcon className="size-10 text-warning" />,
        users: <UsersIcon className="size-10 text-danger" />,
        blog: <NewspaperIcon className="size-10 text-primary" />,
        review: <QueueListIcon className="size-10 text-secondary" />,
        faq: <QuestionMarkCircleIcon className="size-10 text-success" />,
        docs: <DocumentCurrencyDollarIcon className="size-10 text-warning" />,
        us: <BuildingLibraryIcon className="size-10 text-secondary" />,
    };

    return (
        <>
            <Navbar className="" isBordered isMenuOpen={isMenuOpen} onMenuOpenChange={setIsMenuOpen}>
                {/* Логотип и мобильное меню */}
                <NavbarContent className="sm:hidden" justify="start">
                    <NavbarBrand>
                        <Link href="/">
                            <Image src="/assets/img/logo_ph.png" className="w-16 h-auto mr-2" />
                        </Link>
                    </NavbarBrand>
                </NavbarContent>

                {/* Центрированный логотип и ссылки для десктопной версии */}
                <NavbarContent className="hidden sm:flex gap-4" justify="center">
                    <NavbarBrand>
                        <Link href="/">
                            <Image src="/assets/img/logo_ph.png" className="w-16 h-auto mr-2" />
                        </Link>
                    </NavbarBrand>

                    {/* Выпадающее меню */}
                    <Dropdown backdrop="blur">
                        <NavbarItem>
                            <DropdownTrigger>
                                <Button
                                    startContent={<BuildingLibraryIcon className="size-5" />}
                                    disableRipple
                                    className="p-0 bg-transparent data-[hover=true]:bg-transparent font-semibold"
                                    endContent={<ChevronDownIcon className="size-4" />}
                                    radius="sm"
                                    variant="light"
                                >
                                    Компания
                                </Button>
                            </DropdownTrigger>
                        </NavbarItem>

                        <DropdownMenu aria-label="Features">
                            <DropdownItem key="autoscaling" description="Свяжитесь с нами" startContent={icons.activity} onPress={() => handleNavigation("/contacts")}>
                                Контакты
                            </DropdownItem>
                            <DropdownItem key="metrics" description="Больше информации компании" startContent={icons.us} onPress={() => handleNavigation("/about")}>
                                О нас
                            </DropdownItem>
                            <DropdownItem key="uptime" description="Документация проекта" startContent={icons.docs} onPress={() => window.open("https://enzer-exchange.gitbook.io/enzer-exchange-doc", "_blank")}>
                                Документы
                            </DropdownItem>
                            <DropdownItem key="users" description="Все доступные вакансии" startContent={icons.users} onPress={() => handleNavigation("/vacancies")}>
                                Вакансии
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>

                    {/* Дополнительные ссылки */}
                    <Dropdown backdrop="blur">
                        <NavbarItem>
                            <DropdownTrigger>
                                <Button
                                    startContent={<BoltIcon className="size-5" />}
                                    disableRipple
                                    className="p-0 bg-transparent data-[hover=true]:bg-transparent font-semibold"
                                    endContent={<ChevronDownIcon className="size-4" />}
                                    radius="sm"
                                    variant="light"
                                >
                                    Полезное
                                </Button>
                            </DropdownTrigger>
                        </NavbarItem>

                        <DropdownMenu aria-label="Features">
                            <DropdownItem key="reviews" description="Отзывы наших клиентов" startContent={icons.review} onPress={() => handleNavigation("/reviews")}>
                                Отзывы
                            </DropdownItem>
                            <DropdownItem key="faq" description="Ответы на частые вопросы" startContent={icons.faq} onPress={() => handleNavigation("/faq")}>
                                FAQ
                            </DropdownItem>
                            <DropdownItem key="user" description="Получите больше средств" startContent={icons.user} onPress={() => handleNavigation("/referrals")}>
                                Реферальная Программа
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>

                    <NavbarItem>
                        <Button startContent={<ChatBubbleLeftRightIcon className="size-5" />} onClick={openChatra} className="font-semibold text-inherit" variant="light">
                            Поддержка
                        </Button>
                    </NavbarItem>
                </NavbarContent>

                {/* Правый контент */}
                <NavbarContent justify="end">
                    <NavbarItem className="hidden lg:flex">
                        <Button startContent={<ArrowPathIcon className="size-5" />} className="rounded-3xl" onPress={() => handleNavigation("/")} variant="flat" color="primary">
                            Обмен
                        </Button>
                    </NavbarItem>


                    <Dropdown placement="bottom-end" backdrop="blur">
                        <DropdownTrigger>
                            <Image
                                src="/assets/img/app.png"
                                className="w-10 border-2 border-blue-500 rounded-xl p-2"
                            />
                        </DropdownTrigger>
                        {user ? (
                            <DropdownMenu aria-label="Profile Actions" variant="flat">
                                <DropdownItem key="profile" textValue="Профиль" className="h-14 gap-2">
                                    <p className="font-semibold">Добро пожаловать,</p>
                                    <p className="font-semibold">{user.name}</p>
                                </DropdownItem>
                                <DropdownItem startContent={<HomeIcon className="size-10" />} key="home" description="Ваши чувствительные данные" onPress={() => handleNavigation("/dashboard")}>История обменов</DropdownItem>
                                <DropdownItem startContent={<AdjustmentsHorizontalIcon className="size-10" />} key="settings" description="Ваши чувствительные данные" onPress={() => handleNavigation("/profile")}>Настройки</DropdownItem>
                                <DropdownItem
                                    startContent={<FlagIcon className="size-10" />}
                                    key="team_settings"
                                    description="Отправьте репорт о работе сервиса"
                                    onPress={onOpen} // Открываем модалку для отправки жалобы
                                >
                                    Отправить жалобу
                                </DropdownItem>
                                <DropdownItem className="lg:hidden" key="autoscaling" description="Свяжитесь с нами" startContent={icons.activity} onPress={() => handleNavigation("/contacts")}>
                                    Контакты
                                </DropdownItem>
                                <DropdownItem className="lg:hidden" key="metrics" description="Больше информации компании" startContent={icons.us} onPress={() => handleNavigation("/about")}>
                                    О нас
                                </DropdownItem>
                                <DropdownItem className="lg:hidden" key="uptime" description="Документация проекта" startContent={icons.docs} onPress={() => window.open("https://enzer-exchange.gitbook.io/enzer-exchange-doc", "_blank")}>
                                    Документы
                                </DropdownItem>
                                <DropdownItem className="lg:hidden" key="users" description="Все доступные вакансии" startContent={icons.users} onPress={() => handleNavigation("/vacancies")}>
                                    Вакансии
                                </DropdownItem>
                                <DropdownItem className="lg:hidden" key="reviews" description="Отзывы наших клиентов" startContent={icons.review} onPress={() => handleNavigation("/reviews")}>
                                    Отзывы
                                </DropdownItem>
                                <DropdownItem className="lg:hidden" key="faq" description="Ответы на частые вопросы" startContent={icons.faq} onPress={() => handleNavigation("/faq")}>
                                    FAQ
                                </DropdownItem>
                                <DropdownItem className="lg:hidden" description="Связь с проектом" startContent={icons.faq} onClick={openChatra}>
                                    Поддержка
                                </DropdownItem>
                                <DropdownItem className="lg:hidden" key="user" description="Получите больше средств" startContent={icons.user} onPress={() => handleNavigation("/referrals")}>
                                    Реферальная Программа
                                </DropdownItem>
                            </DropdownMenu>
                        ) : (
                            <DropdownMenu aria-label="Profile Actions" variant="flat">
                                <DropdownItem key="profile" className="h-14 gap-2">
                                    <p className="font-semibold">Добро пожаловать,</p>
                                    <p className="font-semibold">Гость</p>
                                </DropdownItem>
                                <DropdownItem startContent={<AdjustmentsHorizontalIcon className="size-10" />} onPress={() => handleNavigation("/login")}>Войти</DropdownItem>
                                <DropdownItem
                                    startContent={<FlagIcon className="size-10" />}
                                    key="team_settings"
                                    description="Отправьте репорт о работе сервиса"
                                    onPress={onOpen} // Открываем модалку для отправки жалобы
                                >
                                    Отправить жалобу
                                </DropdownItem>
                                <DropdownItem className="lg:hidden" key="autoscaling" description="Свяжитесь с нами" startContent={icons.activity} onPress={() => handleNavigation("/contacts")}>
                                    Контакты
                                </DropdownItem>
                                <DropdownItem className="lg:hidden" key="metrics" description="Больше информации компании" startContent={icons.us} onPress={() => handleNavigation("/about")}>
                                    О нас
                                </DropdownItem>
                                <DropdownItem className="lg:hidden" key="uptime" description="Документация проекта" startContent={icons.docs} onPress={() => window.open("https://enzer-exchange.gitbook.io/enzer-exchange-doc", "_blank")}>
                                    Документы
                                </DropdownItem>
                                <DropdownItem className="lg:hidden" key="users" description="Все доступные вакансии" startContent={icons.users} onPress={() => handleNavigation("/vacancies")}>
                                    Вакансии
                                </DropdownItem>
                                <DropdownItem className="lg:hidden" key="reviews" description="Отзывы наших клиентов" startContent={icons.review} onPress={() => handleNavigation("/reviews")}>
                                    Отзывы
                                </DropdownItem>
                                <DropdownItem className="lg:hidden" key="faq" description="Ответы на частые вопросы" startContent={icons.faq} onPress={() => handleNavigation("/faq")}>
                                    FAQ
                                </DropdownItem>
                                <DropdownItem className="lg:hidden" description="Связь с проектом" startContent={icons.faq} onClick={openChatra}>
                                    Поддержка
                                </DropdownItem>
                                <DropdownItem className="lg:hidden" key="user" description="Получите больше средств" startContent={icons.user} onPress={() => handleNavigation("/referrals")}>
                                    Реферальная Программа
                                </DropdownItem>
                            </DropdownMenu>
                        )}
                    </Dropdown>
                </NavbarContent>
            </Navbar>
            {/* Модалка для отправки жалобы */}
            <Modal placement='center' backdrop="blur" isOpen={isOpen} onOpenChange={onOpenChange}>
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader>Отправить жалобу</ModalHeader>
                            <ModalBody>
                                <Input
                                    label="Ваш Email"
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                                <Input
                                    label="Характер проблемы"
                                    id="complaint"
                                    className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                                    value={complaint}
                                    onChange={(e) => setComplaint(e.target.value)}
                                    required
                                />
                            </ModalBody>
                            <ModalFooter>
                                <Button color="danger" variant="light" onPress={onClose}>
                                    Закрыть
                                </Button>
                                <Button color="primary" onPress={handleSubmitComplaint}>
                                    Отправить
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </>
    );
};

export default NavbarComponent;
